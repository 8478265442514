import { Image, Text, Title } from '@mantine/core';
import { Script } from 'gatsby';
import React from 'react';
import docking from '../images/docking.jpg';
import officeBuilding from '../images/office-building.jpg';
import officeBuilding2 from '../images/office-building2.jpg';
import boston from '../images/boston.jpg';
import './about.less';
import SEO from "../components/seo";

const locations = [
  {
    key: 'shaoxing',
    name: 'Shaoxing Production Facility',
    address: ['Room 403, Qihang Building No.5, Technology Park, 586 Xihuan Road', 'Kebei Economic Development Zone, Keqiao District, Shaoxing'],
    phone: '0575-8559 9859',
    officePic: officeBuilding,
    lng: 120.526009,
    lat: 30.143615,
  },
  {
    key: 'shanghai',
    name: 'Shanghai Research and Development Center',
    address: ['Shanghai International Energy Innovation Center', '555 Changjiang Road, Boshan District, Shanghai'],
    phone: '021-6056 0385',
    officePic: officeBuilding2,
    lng: 121.496541,
    lat: 31.354681,
  },
  {
    key: 'boston',
    name: 'Boston Research and Development Center',
    address: ['116 John Street, Ste 315,', 'Lowell, MA, 01852'],
    officePic: boston,
    lng: -71.307667,
    lat: 42.647687,
  },
];

const BaiduMap = React.lazy(() =>
  import("../components/baiduMap")
)
const GoogleMap = React.lazy(() => import("../components/googleMap"));

function About() {
  const isSSR = typeof window === "undefined"

  return (<div className="about">
    <Script type="text/javascript" src={`//api.map.baidu.com/api?type=webgl&v=1.0&ak=YBQANGNEOlVLss0BOkkWllicouXVwmdP`}/>
    <section className="bg">
      <Image src={docking} />
      <Text className="text-tag">Contact us to discuss your chemical production needs! Our experts will engage with you quickly.</Text>
    </section>
    <section>
    </section>
    <section className="locations">
      <Title className='title' order={1}>Our Locations</Title>
      {locations.map((item, index) => (
        <div className={`location ${index % 2 ? 'left' : 'right'}`} key={item.name}>
          <div className="map">
            {!isSSR && <GoogleMap lng={item.lng} lat={item.lat} />}
          </div>
          <div className="office">
            <img src={item.officePic} />
            <div className="address">
              <Title order={3}>{item.name}</Title>
              <Text className='detail'>{item.address[0]}<br />{item.address[1]}</Text>
              {item.phone && <Text className="phone">Phone: {item.phone}</Text>}
            </div>
          </div>
        </div>
      ))}
    </section>
  </div>);
}

export default About;

export const Head = () => (<SEO />)
